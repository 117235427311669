import React, { useState, createContext, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { AnimatedSwitch } from "react-router-transition";

import "./App.css";

import ProtectedRoute, {
  ProtectedExco,
} from "./components/ProtectedRoute/ProtectedRoute.jsx";
import Loading from "./components/Loading/Loading";

// Components
const MobileNav = React.lazy(() => import("./components/MobileNav/MobileNav"));
const ScrollToTop = React.lazy(() => import("./ScrollToTop"));
const Verify = React.lazy(() => import("./pages/Verify/Verify"));
const ExecutiveProfile = React.lazy(() =>
  import("./pages/ExecutiveProfile/ExecutiveProfile")
);
const ExecutiveReports = React.lazy(() =>
  import("./pages/ExecutiveReports/ExecutiveReports")
);
const ExecutiveReceipt = React.lazy(() =>
  import("./pages/ExecutiveReceipt/ExecutiveReceipt")
);

// Pages
const Homepage = React.lazy(() => import("./pages/Homepage/Homepage"));
const Signup = React.lazy(() => import("./pages/Signup/Signup"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const About = React.lazy(() => import("./pages/About/About"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Faq = React.lazy(() => import("./pages/FAQ/Faq"));
const ProfilePage = React.lazy(() => import("./pages/Profile/Profile"));
const NewPayment = React.lazy(() => import("./pages/NewPayment/NewPayment"));
const Help = React.lazy(() => import("./pages/Help/Help"));
const PaymentPage = React.lazy(() => import("./pages/Payments/Payments"));
const ExecutiveSignup = React.lazy(() =>
  import("./pages/ExecutiveSignup/ExecutiveSignup")
);
const ExecutiveLogin = React.lazy(() =>
  import("./pages/ExecutiveLogin/ExecutiveLogin")
);
const ExecutiveDashboard = React.lazy(() =>
  import("./pages/ExecutiveDashboard/ExecutiveDashboard")
);
const Receipt = React.lazy(() => import("./pages/Receipt/Receipt"));
const ForgotPassword = React.lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./pages/ResetPassword/ResetPassword")
);
const Logout = React.lazy(() => import("./pages/Logout/Logout"));
const GeneratePDF = React.lazy(() => import("./pages/GeneratePDF/GeneratePDF"));
const Page404 = React.lazy(() => import("./pages/Page404/Page404"));
const AccountReview = React.lazy(() =>
  import("./pages/AccountReview/AccountReview")
);

// import {getCookie} from './utilities/getCookies';
// import { getUserDetails } from "./utilities/getUserDetails";
// import { set } from "js-cookie";

export const LoggedInContext = createContext();
const App = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [userType, setUserType] = useState("");

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  const checkUser = () => {
    const usr = JSON.parse(sessionStorage.getItem("mduser"));
    const tkn = sessionStorage.getItem("mdtoken");
    if (usr && tkn) {
      setTimeout(() => {
        setUser(usr);
        setToken(tkn);
        setUserType(usr.roles);
        setIsLoggedIn(true);
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
    }
  };
  const handleLogin = ({ user, token }) => {
    console.log(user, token);
    sessionStorage.setItem("mdtoken", token);
    sessionStorage.setItem("mduser", JSON.stringify(user));
    sessionStorage.setItem("role", user.roles);
    setUser(user);
    setToken(token);
    setUserType(user.roles);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    setUser({});
    setToken("");
    setUserType("");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);

  // const routeProps = {loading, isLoggedIn, isLoginPage: false};
  return (
    <LoggedInContext.Provider
      value={{ isLoggedIn, user, token, handleLogin, handleLogout, userType }}
    >
      <Suspense fallback={<Loading />}>
        <Router>
          <ScrollToTop />
          <div>
            <MobileNav
              toggleMobileNav={toggleMobileNav}
              mobileNav={mobileNav}
            />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Homepage
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route path="/register" component={Signup} />
              <Route
                path="/about"
                render={(props) => (
                  <About
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Contact
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/faq"
                render={(props) => (
                  <Faq
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/verify"
                render={(props) => (
                  <Verify
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/forgot-password"
                render={(props) => (
                  <ForgotPassword
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/reset/:token"
                render={(props) => (
                  <ResetPassword
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />
              <Route
                path="/generate-pdf"
                render={(props) => (
                  <GeneratePDF
                    {...props}
                    toggleMobileNav={toggleMobileNav}
                    mobileNav={mobileNav}
                  />
                )}
              />

              <Route
                path="/login"
                render={() =>
                  ProtectedRoute(Login, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                    isLoginPage: true,
                  })
                }
              />
              <Route
                path="/dashboard"
                render={() =>
                  ProtectedRoute(Dashboard, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/logout"
                render={() =>
                  ProtectedRoute(Logout, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />

              {/* USER */}
              <Route
                path="/profile"
                render={() =>
                  ProtectedRoute(ProfilePage, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route path="/receipts" component={Receipt} />
              <Route
                path="/payments"
                render={() =>
                  ProtectedRoute(PaymentPage, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/new"
                render={() =>
                  ProtectedRoute(NewPayment, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/help"
                render={() =>
                  ProtectedRoute(Help, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />

              {/* EXCO */}
              <Route
                path="/exco/signup"
                render={() =>
                  ProtectedExco(ExecutiveSignup, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                    isLoginPage: true,
                  })
                }
              />
              <Route
                path="/exco/login"
                render={() =>
                  ProtectedExco(ExecutiveLogin, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                    isLoginPage: true,
                  })
                }
              />
              <Route
                path="/exco/dashboard"
                render={() =>
                  ProtectedExco(ExecutiveDashboard, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/exco/profile"
                render={() =>
                  ProtectedExco(ExecutiveProfile, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/exco/review"
                render={() =>
                  ProtectedExco(AccountReview, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/exco/reports"
                render={() =>
                  ProtectedExco(ExecutiveReports, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route
                path="/exco/receipts"
                render={() =>
                  ProtectedExco(ExecutiveReceipt, {
                    isLoggedIn,
                    loading,
                    userType,
                    handleLogout,
                  })
                }
              />
              <Route path="*" component={Page404} />
            </Switch>
          </div>
        </Router>
      </Suspense>
    </LoggedInContext.Provider>
  );
};

export default App;
